import PropTypes from "prop-types"
import React from "react"
import { Link, graphql, withPrefix, StaticQuery } from "gatsby"
import $ from "jquery"

import Marquee from "./marquee.jsx"
import SubMenuPage from "./subheader.jsx"

import { navigate } from "@reach/router"

function openSearch() {
  navigate("/search")
}

function handleMenu(e) {
  if ($("ul.slicknav_hidden").length) {
    $("body").css("overflow", "hidden")
    $("a.slicknav_btn")
      .removeClass("slicknav_collapsed")
      .addClass("slicknav_open")
    $("ul.slicknav_nav").removeClass("slicknav_hidden").css("display", "block")
  } else {
    $("body").css("overflow", "")
    $("a.slicknav_btn")
      .removeClass("slicknav_open")
      .addClass("slicknav_collapsed")
    $("ul.slicknav_nav").addClass("slicknav_hidden").css("display", "none")
  }
}

function handleLink(e) {
  $("body").css("overflow", "")
  // return true;
}

const Header = ({
  siteTitle,
  marquee,
  isContent,
  button,
  menus,
  parent,
  data,
}) => {
  const setting = data.wagtail.setting

  return (
    <header className="header-menu">
      <div className="main-menu">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-xs-2">
              <div className="logo">
                <Link to="/">
                  <img
                    src={setting.logo.src}
                    alt="logo"
                    width="139"
                    height="50"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-10 col-xs-10 d-none d-lg-block text-right">
              <nav className="nav-menu">
                <ul id="navigation">
                  <li className={parent === "athletes" ? "active" : null}>
                    <Link to="/athletes">ATHLETES</Link>
                  </li>
                  <li className={parent === "coaches" ? "active" : null}>
                    <Link to="/coaches">COACHES</Link>
                  </li>
                  <li className={parent === "officials" ? "active" : null}>
                    <Link to="/officials">OFFICIALS</Link>
                  </li>
                  <li className={parent === "clubs" ? "active" : null}>
                    <Link to="/clubs">CLUBS</Link>
                  </li>
                  <li className={parent === "about" ? "space active" : "space"}>
                    <Link to="/about">ABOUT</Link>
                  </li>
                  <li className={parent === "news" ? "active" : null}>
                    <Link to="/news">NEWS</Link>
                  </li>
                  <li className={parent === "sport-safety" ? "active" : null}>
                    <Link to="/sport-safety">SPORT SAFETY</Link>
                  </li>
                  <li className={parent === "contact" ? "active" : null}>
                    <Link to="/contact">CONTACT</Link>
                  </li>
                  <li className="src">
                    <button className="openBtn" onClick={openSearch}>
                      <img src={withPrefix("images/search.png")} alt="search" />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-12 d-block d-lg-none">
              <div className="mobile_menu">
                <div className="slicknav_menu">
                  <a
                    href="#menu"
                    data-menu="true"
                    onClick={handleMenu}
                    aria-haspopup="true"
                    role="button"
                    tabIndex="0"
                    className="slicknav_btn slicknav_collapsed"
                    style={{ outline: "currentcolor none medium" }}
                  >
                    <span className="slicknav_menutxt">MENU</span>
                    <span className="slicknav_icon">
                      <span className="slicknav_icon-bar"></span>
                      <span className="slicknav_icon-bar"></span>
                      <span className="slicknav_icon-bar"></span>
                    </span>
                  </a>
                  <ul
                    className="slicknav_nav slicknav_hidden"
                    style={{ display: "none" }}
                    aria-hidden="true"
                  >
                    <li>
                      <Link
                        data-menu="true"
                        onClick={handleLink}
                        to="/athletes"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        ATHLETES
                      </Link>
                    </li>
                    <li>
                      <Link
                        data-menu="true"
                        onClick={handleLink}
                        to="/coaches"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        COACHES
                      </Link>
                    </li>
                    <li>
                      <Link
                        data-menu="true"
                        onClick={handleLink}
                        to="/officials"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        OFFICIALS
                      </Link>
                    </li>
                    <li>
                      <Link
                        data-menu="true"
                        onClick={handleLink}
                        to="/clubs"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        CLUBS
                      </Link>
                    </li>
                    <li className="space">
                      <Link
                        data-menu="true"
                        onClick={handleLink}
                        to="/about"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        ABOUT
                      </Link>
                    </li>
                    <li>
                      <Link
                        data-menu="true"
                        onClick={handleLink}
                        aria-current="page"
                        to="/news"
                        role="menuiatem"
                        tabIndex="-1"
                      >
                        NEWS
                      </Link>
                    </li>
                    <li>
                      <Link
                        data-menu="true"
                        onClick={handleLink}
                        to="/sport-safety"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        SPORT SAFETY
                      </Link>
                    </li>
                    <li>
                      <Link
                        data-menu="true"
                        onClick={handleLink}
                        to="/contact"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        CONTACT
                      </Link>
                    </li>
                    <li className="src">
                      <button className="openBtn" onClick={openSearch}>
                        <img
                          src={`../../images/search_mobile.png`}
                          alt="search"
                        />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Marquee data={marquee} />
      </div>
      {isContent && (
        <SubMenuPage
          menus={menus}
          button={button}
          parent={parent}
          siteTitle={siteTitle}
        />
      )}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "",
}

export default function MyHeader(props) {
  return (
    <StaticQuery
      query={graphql`
        {
          wagtail {
            setting(name: "NavSettings") {
              ... on NavSettings {
                titleNav
                logo {
                  src
                  file
                  width
                  height
                }
              }
            }
          }
        }
      `}
      render={(data) => <Header data={data} {...props} />}
    />
  )
}
