import React from "react"

const Icon = ({ name, description }) => {
  return (
    <i
      className={name}
      role="presentation"
      aria-label={description}
      title={description}
    ></i>
  )
}

export default Icon
