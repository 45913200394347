/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

function SEO({ subTitle }) {
  let title = "Swim Ontario"

  if (subTitle) {
    title += ` - ${subTitle}`
  }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
    </Helmet>
  )
}

SEO.defaultProps = {
  subtitle: "",
}

SEO.propTypes = {
  subtitle: PropTypes.string.isRequired,
}

export default SEO
