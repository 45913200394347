import React from "react"
import Icon from "./icon"

const IconLink = ({ url, target, classname, icon, alt }) => {
  return (
    <a href={url} target={target} className={classname} aria-label={alt}>
      <Icon name={icon} description={alt} />
    </a>
  )
}

export default IconLink
