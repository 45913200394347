import React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

const Marquee = ({ data }) => {
  console.log(data)
  if (data.length <= 0) {
    return null
  }
  return (
    <div className="tickerwrapper">
      <ul className="list">
        {data.map((item, index) => {
          console.log({ item, index })
          return (
            <li className="listitem" key={`${item.text}-${index}`}>
              <span>{item.text}</span>
            </li>
          )
        })}
      </ul>
      <Helmet>
        <script
          src={withPrefix("scripts/text-slider.js")}
          type="text/javascript"
        />
      </Helmet>
    </div>
  )
}

Marquee.defaultProps = {
  data: [],
}

export default Marquee
