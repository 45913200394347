import { Link, graphql, StaticQuery } from "gatsby"
import React from "react"
import Elink from "./elink"
import IconLink from "./iconlink"

const Footer = ({ data }) => {
  const twURL = `https://twitter.com/${data.wagtail.setting.twitter}`
  const igURL = `https://instagram.com/${data.wagtail.setting.instagram}`
  const year = new Date().getFullYear()
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-4">
            <div className="footer-content">
              {data.wagtail.setting.footerDescription && (
                <p>
                  Swim Ontario serves as the provincial governing body of
                  competitive swimming in Ontario. We inspire Canadians through
                  world leading performances to embrace a lifestyle of swimming,
                  sport, fitness and health.
                </p>
              )}
            </div>
            <div className="footer-socail footer-content">
              <ul>
                <li>
                  <IconLink
                    alt="Find us on Facebook"
                    icon="fab fa-facebook-f"
                    url={data.wagtail.setting.facebook}
                    target="_blank"
                  />
                </li>
                <li>
                  <IconLink
                    alt="Find us on Twitter"
                    icon="fab fa-twitter"
                    url={twURL}
                    target="_blank"
                  />
                </li>
                <li>
                  <IconLink
                    alt="Find us on Instagram"
                    icon="fab fa-instagram"
                    url={igURL}
                    target="_blank"
                  />
                </li>
                <li>
                  <IconLink
                    alt="Find us on Youtube"
                    icon="fab fa-youtube"
                    url={data.wagtail.setting.youtube}
                    target="_blank"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-lg-6">
            <div className="footer-menu footer-content">
              <ul>
                <li>
                  <Link to="/athletes">Athletes</Link>
                </li>
                <li>
                  <Link to="/coaches">Coaches</Link>
                </li>
                <li>
                  <Link to="/officials">Officials</Link>
                </li>
                <li>
                  <Link to="/clubs">Clubs</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/news">News</Link>
                </li>
                <li>
                  <Link to="/sport-safety">Sport Safety</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/search">Search</Link>
                </li>
              </ul>
              {/* <ul> */}
              {/*   <li> */}
              {/*     <Link to="/">Privacy</Link> */}
              {/*   </li> */}
              {/*   <li> */}
              {/*     <Link to="/">Terms & Conditions</Link> */}
              {/*   </li> */}
              {/*   <li> */}
              {/*     <Link to="/">Accessiblity</Link> */}
              {/*   </li> */}
              {/*   <li> */}
              {/*     <Link to="/">Disclaimer</Link> */}
              {/*   </li> */}
              {/* </ul> */}
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-2 text-right">
            <div className="fotter-logo">
              <Link to="/">
                <img
                  src={data.wagtail.setting.smallLogo.src}
                  alt="small-logo"
                />
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="footer-copyright footer-content">
              <p>Swim Ontario &copy; {year}</p>
              <p>
                Website created by{" "}
                <Elink
                  text="Believeco"
                  url={data.wagtail.setting.creatorLink}
                  target="_blank"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default function MyFooter(props) {
  return (
    <StaticQuery
      query={graphql`
        {
          wagtail {
            setting(name: "NavSettings") {
              ... on NavSettings {
                twitter
                facebook
                instagram
                youtube
                footerDescription
                creatorLink
                smallLogo {
                  src
                  width
                  height
                }
              }
            }
          }
        }
      `}
      render={data => <Footer data={data} {...props} />}
    />
  )
}
