/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { withPrefix } from "gatsby"

import SEO from "./seo"
import Footer from "./footer"
import Header from "./header"
import Helmet from "react-helmet"

const Layout = ({
  children,
  header,
  subMenu,
  button,
  isContent,
  parent,
  siteTitle,
}) => {
  return (
    <div>
      <SEO subTitle={siteTitle} />
      <Header
        siteTitle={siteTitle}
        marquee={header}
        menus={subMenu}
        button={button}
        isContent={isContent}
        parent={parent}
      />
      {children}
      <Footer />
      <Helmet>
        <script
          src={withPrefix("scripts/external_links.js")}
          type="text/javascript"
        />
      </Helmet>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  siteTitle: "",
}
export default Layout
