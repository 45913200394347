import React from "react"
import { Link } from "gatsby"

const ContentButton = ({ button }) => {
  if (!button) {
    return null
  }
  return (
    <div className="col-lg-2 text-right">
      <a
        className="theme-btn-secondary"
        href={button.url}
        alt={button.text}
        target="__blank__"
      >
        {button.text}
      </a>
    </div>
  )
}

const SubMenuPage = ({ menus, button, parent, siteTitle }) => {
  if (menus === undefined) {
    return null
  } else if (menus.length <= 0) {
    return null
  }
  const menuFiltered = menus.filter(item => item.live && !item.isProtected)
  return (
    <div className="main-submenu">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xs-12 col-lg-10 d-lg-block">
            <nav className="nav-menu">
              <ul className="navigation">
                {menuFiltered.map((item, index) => {
                  if (
                    button &&
                    item.title.toLowerCase() === button.text.toLowerCase()
                  ) {
                    return ""
                  }

                  let url = `${item.slug}`
                  if (parent) {
                    url = `/${parent}/${item.slug}`
                  }
                  return (
                    <li
                      key={item.title}
                      className={
                        siteTitle === item.title
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link data-menu="true" className="sub-item" to={url}>
                        {item.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
          <ContentButton button={button} />
        </div>
      </div>
    </div>
  )
}

export default SubMenuPage
